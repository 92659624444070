
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUILayoutBasic from "@/views/resources/documentation/element-ui/basic/layout/BasicLayout.vue";
import EUIColumnSpacing from "@/views/resources/documentation/element-ui/basic/layout/ColumnSpacing.vue";
import EUIHybridLayout from "@/views/resources/documentation/element-ui/basic/layout/HybridLayout.vue";
import EUIColumnOffset from "@/views/resources/documentation/element-ui/basic/layout/ColumnOffset.vue";
import EUIAlignment from "@/views/resources/documentation/element-ui/basic/layout/Alignment.vue";
import EUIResponsiveLayout from "@/views/resources/documentation/element-ui/basic/layout/ResponsiveLayout.vue";

export default defineComponent({
  name: "layout",
  components: {
    EUILayoutBasic,
    EUIColumnSpacing,
    EUIHybridLayout,
    EUIColumnOffset,
    EUIAlignment,
    EUIResponsiveLayout
  },
  setup() {
    setCurrentPageTitle("Layout");
  }
});
